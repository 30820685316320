import clientThumb1 from "../../assets/images/partnerprojects/NFA.png";
import clientThumb2 from "../../assets/images/partnerprojects/Aura.png";
import clientThumb3 from "../../assets/images/partnerprojects/KronicKatz.png";
import clientThumb4 from "../../assets/images/partnerprojects/LordLabz.png";

const data = [
  clientThumb1,
  clientThumb2,
  clientThumb3,
  clientThumb4,
];

export default data;
