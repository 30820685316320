import aboutCardIcon1 from "../../images/icon/marketplace.png";
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg";
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon5.png";
import aboutCardIcon4 from "../../images/icon/aboutus_card_icon3.png";
import aboutCardIcon5 from "../../images/icon/aboutus_card_icon1.png";
import aboutCardIcon6 from "../../images/icon/aboutus_card_icon6.png";

const data = {
  aboutDescription1:
    'Zombeze NFT series known as "ZOE - Zombeze of Enlightenment" is a hand illustrated NFT series which evolves and transforms into much more. This is the first project released from highly skilled artist OdelayGuero. Created, backed and released by LordLabz, Zombeze is a web3 Enlightened Utility project which pushes the boundaries of blockchain technology & financial systems.',
  aboutDescription2:
    " Packed with a stack of blockchain enhanced content, IRL events, a Lore leading to transformation of the industry. Zombeze is aimed at bringing people from all over the world together with inclusivity and togetherness through art, community, gaming, education and more...",
  aboutInfo: [
    {
      icon: aboutCardIcon1,
      title: "NFT Marketplace",
      text: "Buy, Sell and Trade  Stake and earnAura will light your path in web3 and beyond",
    },
    {
      icon: aboutCardIcon2,
      title: "Play to Earn",
      text: "P2E 8bit Zombeze game for the community on launch, earn $Zoe, upgrade items and more",
    },
    {
      icon: aboutCardIcon3,
      title: "Exciting Lore",
      text: "The Lore of the All Enlightened from Zombeze to Bounty Hunterz through to The Experiment lightens the path of positive energy",
    },
    {
      icon: aboutCardIcon4,
      title: "Mutate and Evolve",
      text: "Zombeze sent to earth by the higher beings will mutate and evolve, How and When will be discovered by the community",
    },
    {
      icon: aboutCardIcon5,
      title: "NFT Comic series",
      text: "Upon sellout an evolving NFT Comic Book series will be released uncovering the Lore with LE Front Covers per Access Pass",
    },
    {
      icon: aboutCardIcon6,
      title: "Charity Support",
      text: "10% of mint proceeds goes towards combating Mental Health in the blockchain industry",
    },
  ],
};

export default data;
