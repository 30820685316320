import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  /*{
    thumb: openseaIcon,
    url: "https://opensea.io/collection/cryptozombeze",
  },*/
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/cryptozombeze",
  },

];

export default data;
