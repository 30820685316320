//import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";

import avatarThumb1 from "../../images/team/crew_avatar1.png";
import avatarThumb2 from "../../images/team/crew_avatar2.png";
import avatarThumb3 from "../../images/team/crew_avatar3.png";
import avatarThumb4 from "../../images/team/crew_avatar4.png";
import avatarThumb5 from "../../images/team/crew_avatar5.png";
import avatarThumb6 from "../../images/team/crew_avatar6.png";
import avatarThumb7 from "../../images/team/crew_avatar7.png";
import avatarThumb8 from "../../images/team/crew_avatar8.png";
import avatarThumb9 from "../../images/team/crew_avatar9.png";
import avatarThumb10 from "../../images/team/crew_avatar10.png";


const data = [
  {
    avatar: avatarThumb1,
    name: "Az Brown",
    designation: "Founder",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/Realm_of_Crypto",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
  {
    avatar: avatarThumb2,
    name: "OdelayGuero",
    designation: "Artist",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
  {
    avatar: avatarThumb6,
    name: "Dustin Turska",
    designation: "Lead Developer",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/dustin-turska-5a604887/",
      },
      {
        icon: <FaTwitter />,
        url: "https://twitter.com/KronicLabzDev",
      },
      {
        icon: <FaInstagram />,
        url: "https://www.instagram.com/kronickatz/",
      },
    ],
    */
  },
  {
    avatar: avatarThumb7,
    name: "Gravv",
    designation: "Graphics/Video Production",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
  {
    avatar: avatarThumb8,
    name: "Farhana ",
    designation: "Socials Coordinator",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
  {
    avatar: avatarThumb4,
    name: "Tomm",
    designation: "Developer",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
  {
    avatar: avatarThumb9,
    name: "Adnan ",
    designation: "Lead Mod",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
  {
    avatar: avatarThumb10,
    name: "CJ ",
    designation: "Advisor",
    /*socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      },
      {
        icon: <FaTwitter />,
        url: "#",
      },
      {
        icon: <FaInstagram />,
        url: "#",
      },
    ],
    */
  },
];

export default data;
