import { useModal } from "../../../../utils/ModalContext";
//import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

//import characterThumb from "../../../../assets/images/nft/Character1.png";
//import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
//import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import { useEffect, useState } from "react";
import { totalMintCount } from "../../../../utils/web3mint";

const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();
  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      console.log(totaltMintedItems);
      setRemaining(totaltMintedItems);
    };

    calculateRemainingItems();
  }, []);
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <div className="banner_buttons">
                <a href="https://www.mint.zombezeofficial.xyz/">
                  <Button
                    lg
                    variant="red"
                    onclick="window.location.href='https://www.zombezeofficial.xyz/';"
                  >
                    Mint now
                  </Button>
                </a>
                <a href="http://www.packs.zombezeofficial.xyz/">
                  <Button
                    lg
                    variant="red"
                    onclick="window.location.href='http://www.packs.zombezeofficial.xyz/';"
                  >
                    Open Packs
                  </Button>
                </a>
              </div>
              <div className="coin-info">
                <span>Max 10 NFTs per wallet . Price 0.666 ETH + gas</span>
                <span>
                  MINT IS LIVE{" "}
                  <span className="highlighted">
                    Until ALL of the Undead are Hunting
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
