const data = [
  {
    title: "What are Zombeze?",
    text: "Zombeze NFT series known as 'ZOE - Zombeze of Enlightenment' is a hand illustrated NFT series which evolves and transforms into much more.",
  },
  {
    title: "How, When, and Where?",
    text: "Zombeze will be a stealth launch, meaning that the project will be launched without any prior announcement, on the Ethereum blockchain. Mint is 0.666 ETH.",
  },
  {
    title: "What is the total supply of Zombeze?",
    text: "6,666 Max total supply with 666 reserved for the team and marketing uses.",
  },
  {
    title: "Where can I buy Zombeze after mint?",
    text: "Zombeze will be listed on OpenSea and Aura, our soon to be released NFT Marketplace.",
  },
];

export default data;
