import characterThumb1 from "../../images/nft/carousel_img_1.png";
import characterThumb2 from "../../images/nft/carousel_img_2.png";
import characterThumb3 from "../../images/nft/carousel_img_3.png";
import characterThumb4 from "../../images/nft/carousel_img_4.png";
import characterThumb5 from "../../images/nft/carousel_img_5.png";
import characterThumb6 from "../../images/nft/carousel_img_6.png";
import characterThumb7 from "../../images/nft/carousel_img_7.png";
import characterThumb8 from "../../images/nft/carousel_img_8.png";
import characterThumb9 from "../../images/nft/carousel_img_9.png";
import characterThumb10 from "../../images/nft/carousel_img_10.png";  
import characterThumb11 from "../../images/nft/carousel_img_11.png";
import characterThumb12 from "../../images/nft/carousel_img_12.png";
import characterThumb13 from "../../images/nft/carousel_img_13.png";
import characterThumb14 from "../../images/nft/carousel_img_14.png";

const data = [
  {
    thumb: characterThumb1,
  },
  {
    thumb: characterThumb2,
  },
  {
    thumb: characterThumb3,
  },
  {
    thumb: characterThumb4,
  },
  {
    thumb: characterThumb5,
  },
  {
    thumb: characterThumb6,
  },
  {
    thumb: characterThumb7,
  },
  {
    thumb: characterThumb8,
  },
  {
    thumb: characterThumb9,
  },
  {
    thumb: characterThumb10,
  },
  {
    thumb: characterThumb11,
  },
  {
    thumb: characterThumb12,
  },
  {
    thumb: characterThumb13,
  },
  {
    thumb: characterThumb14,
  },
];

export default data;
