import contract from '../contracts/bithuabi.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../config';



export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0xb590475B5f31D04e6cE22ddE909156F46A75384b";
        const nftContract = new ethers.Contract(contractAddress, contract, signer);
        let txnHash = await nftContract.claim(ethereum.selectedAddress, mint_amount, {
            gasLimit: "285000",
            value: ethers.utils.parseEther((0.666 * mint_amount).toString())
        })
        return txnHash
    }
}


export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const contractAddress = "0xb590475B5f31D04e6cE22ddE909156F46A75384b";
        const nftContract = new ethers.Contract(contractAddress, contract, signer);

        let totalMint = await nftContract.maxTotalSupply();

        return totalMint;
    }
}
