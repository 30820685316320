import zoeClean from "../../images/icon/zoe-clean.png";
import zoeBlood from "../../images/icon/zoe.png";

const data = [
  {
    icon: zoeClean,
    // title: "Phase 01 ",
    subtitle: "NFT Drop",
    text: "Stealth Official Launch will have an instant reveal and minters will experinece the first Pack claim on ETH network with each mint unlocking a pack full of $Zoe tokens and an Access Pass",
  },
  {
    icon: zoeBlood,
    // title: "Phase 02",
    subtitle: "Project Funds",
    text: "Allocations for development, marketing , team, community, charity, airdrops and more Team Gnosis multi-sig wallet to be setup on mint out",
  },
  {
    icon: zoeClean,
    // title: "Phase 03",
    subtitle: "Airdrops",
    text: "On mint out the team will airdrop to each holder a Zombeze Phunk’d, Zombeze Pixel and of course the Zombeze NFT Comi",
  },
  {
    icon: zoeBlood,
    // title: "Phase 04",
    subtitle: "ZOE Token",
    text: "$Zoe will be used on Aura for fees in trading, NFT minting and more $Zoe used in Zombeze P2E as in game currency Zombeze can be staked for $Zoe Liquidity Pools to be offered to community with varied % incentives",
  },
  {
    icon: zoeClean,
    //  title: "Phase 05",
    subtitle: "NFT Comic Series",
    text: "Zombeze NFT Comic series introduces the evolving Lore with LE covers per access passes Community input into storyline and characters",
  },
  {
    icon: zoeBlood,
    //  title: "Phase 06",
    subtitle: "Aura NFT Marketplace",
    text: "Buy. sell, Trade and even Lease Aura will evolve from the base marketplace to the most advanced web3 dynamic exchange in the industry",
  },
  {
    icon: zoeClean,
    //  title: "Phase 07",
    subtitle: "Community Dao",
    text: "On sellout Community Dao will be created and Dao voting will begin giving the growth of the project to the decisions of the community",
  },
  {
    icon: zoeBlood,
    //  title: "Phase 08",
    subtitle: "P2E Game",
    text: "Play the 8bit Zombeze game, Earn ZOE tokens and upgrade to advance through levels",
  },
];

export default data;
